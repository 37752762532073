export const MASKS = {
  number: /^\d+$/,
  email:
    /^[a-zA-Z0-9](?!.*[._]{2})[a-zA-Z0-9._]+@[a-zA-Z0-9-](?!.*[._]{2})[a-zA-Z0-9-]+(\.[a-zA-Z0-9-](?!.*[._]{2})[a-zA-Z0-9-]+)+$/,
  lettersAndNumbersAndSpecialCharacters:
    /^[a-zA-Zа-яА-Я0-9!@"#" "+<>$%^&*()]+$/,
  letters: /^[a-zA-Zа-яА-Я ]+$/,
  lettersAndSpecialCharacters: /^[a-zA-Zа-яА-Я!@#$%^&*()]+$/,

  numberAndSpace: /^[A-Za-z][A-Za-z ]*$/,
  numberAndSpaceWithCharacters: /^[A-Za-z][A-Za-z0-9 ]*$/,
  numberAndSpaceWithCharsEnRU: /^[A-Za-zА-Яа-яЁё][A-Za-zА-Яа-яЁё0-9 ]*$/,
  numberAndCharacters: /^[A-Za-z0-9]+$/,
  numberAndSpaceAndCharacters: /^[A-Za-zА-Яа-я][A-Za-zА-Яа-я0-9 ]*$/,
  charactersNumbersSpecialSymbols:
    /^(?!.* {2})[a-zA-Zа-яА-Я0-9 _.,;!&()+=^%$\-#@~`\\-_'?]*$/,
};

export const MASKS_CONVERT = {
  HyphensAndUnderscoresWithSmallLetters: /([-_][a-z])/gi,
  Uppercase: /([A-Z])/g,
  snakeCase: /(_\w)/g,
};
