// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-cond-assign */
import { DataRequestItem } from '@/shared/store/admin/Types/requestType';

export function parseMarkdown(markdown: string): DataRequestItem[] {
  const items: DataRequestItem[] = [];
  const regex =
    /<span style='color:green;'>Рейтинг (\d+).<\/span>\*\*?Страница описания:\*\*? ([\s\S]*?)\n\*\*?Файл:\*\*? ([\s\S]*?)\n\n\*\*?Ссылка:\*\*? ([\s\S]*?);\n\n\*\*?Текст из документа:\*\*? ([\s\S]*?)\n\n/g;

  let match;
  while ((match = regex.exec(markdown)) !== null) {
    items.push({
      rating: parseInt(match[1], 10),
      fileName: match[3],
      fileLink: match[4],
      message: match[5].replace(/\n/g, ' ').trim(),
      warning: '',
    });
  }

  return items;
}
