import { UsersList } from '../../Types/responseType';

export function getUser(
  userId: string,
  usersList: UsersList[],
): UsersList | null {
  if (usersList.length === 0) throw new Error('Invalid info list users');

  for (const user of usersList) {
    if (user.id === userId) {
      return user;
    }
  }

  return null;
}
