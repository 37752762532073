export function downloadFile(
  data: BlobPart,
  format: string,
  name: string | null,
) {
  const newName = name ? name.replaceAll('"', "'") : '';
  const a = document.createElement('a');
  const blob = new Blob([data], {
    type: 'application/octet-stream;charset=UTF-8',
  });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${newName}.${format.toLocaleLowerCase()}`;
  a.click();
  window.URL.revokeObjectURL(url);
}
