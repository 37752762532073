export enum MethodRequest {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
}

export const HEADER_FORM_DATA = { 'Content-Type': 'multipart/form-data' };
export const HEADER_JSON = { 'Content-Type': 'application/json' };

export type ErrorType = 'error' | 'warning' | 'info' | 'success';

export type DeleteFolderModalType = {
  isOpen: boolean;
  value: string;
  label?: string;
};
