// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/naming-convention */
import { GetDataFormatLLMProps } from './GetDataFormatLLM.types';

export function getDataFormatLLM({
  data,
  query,
  isLLM = false,
}: GetDataFormatLLMProps) {
  return {
    query,
    user_id: data.requestID,
    return_count: data.returnCount,
    search_params: data.searchParams,
    search_params_chunks: data.searchParamsChunks,
    use_llm: isLLM,
    use_llm_cache: isLLM,
    docs_indexes: data.docsIndexes,
    docs_window: data.docsWindow,
    docs_window_type: data.docsWindowType,
    LLM_params_look_back: data.LLMParamsLookBack,
    LLM_params_look_forward: data.LLMParamsLookForward,
    max_output_tokens: data.maxOutputToken,
    is_jr_collection: data.isJrCollection,
    company_collection: data.companyCollection,
    integration_company_collection: data.integrationFolderName,
  };
}
