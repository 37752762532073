export const ROUTES = {
  main: '/',
  home: '/home',
  signIn: '/signIn',
  sightUp: '/signUp',
  default: '',

  // Dashboard
  controlDocuments: '/controlDocuments',
  controlCompany: '/controlCompany',
  controlUsers: '/controlUsers',
  search: '/search',
  personalAccount: '/personalAccount',
};

export const AUTH_PATH = [ROUTES.signIn, ROUTES.sightUp];

export const NOT_ACCESS_PATH = [ROUTES.signIn, ROUTES.sightUp, ROUTES.default];
