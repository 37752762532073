/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
/* eslint-disable spaced-comment */
/* eslint-disable sort-class-members/sort-class-members */
/* eslint-disable lines-between-class-members */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
import { makeAutoObservable, observable, when, action } from 'mobx';
import { localStorageWrapper } from '@/shared/lib/storageWrapper';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { warning } from 'framer-motion';
import { DeleteFolderModalType, ErrorType } from './Types/types';
import {
  CompanyInfo,
  DocumentsData,
  Folders,
  MessageData,
  UsersList,
} from './Types/responseType';
import {
  CreatePasswordRequest,
  CreateUserRequest,
  DataRequestItem,
  SendMessageContact,
  SettingsSearch,
  SettingsSearchProps,
  SignInRequest,
  SignUpRequest,
  UploadFileRequest,
} from './Types/requestType';
import {
  logout,
  resetErrorSearch,
  setInfoAddUSer,
  setInitSettingsSearch,
  setisLoadingAddUser,
  setIsOpenNavMenu,
  setWidthScreen,
  toggleModal,
  toggleModalUser,
  clearLLMessage,
  create_password,
  createCollection,
  createNewUserByAdmin,
  deleteCollection,
  deleteDocument,
  downloadDocument,
  getCompanyInfo,
  getDocuments,
  getFolders,
  getUsersList,
  onSendMessageLLM,
  onSendMessageRequest,
  setSettingsSearch,
  signIn,
  signUp,
  addMessage,
  performRequest,
  performRequestLLMSearch,
  updateMessage,
  uploadFile,
  reloadDocument,
  sendMessageFromContactWithUs,
  addAlert,
} from './Methods';

export enum StorageKey {
  _UserAuth = 'userAuth',
  _SettingsSearch = 'settingSearch',
}

export enum LoadingStatus {
  none = 'None', //none
  payload = 'PayLoad', //true
  rejected = 'Rejected', //error
  success = 'Success', // false
}

export enum AlertStatus {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export type AlertType = {
  id: number;
  title: string;
  status: AlertStatus;
  visible: boolean;
};
export class Admin {
  isOpenAdmin: boolean = false;
  isOpenModalUploadFile: boolean = false;
  isOpenModalErrorDOc = {
    isOpen: false,
    id: '',
    name: '',
  };
  isOpenModalAddUser: boolean = false;
  isOpenNavMenu: boolean = false;
  isOpenModalContactWithUs: boolean = false;
  isOpenDeleteFolderModal: DeleteFolderModalType = {
    isOpen: false,
    value: '',
    label: '',
  };

  isOpenDeleteFunctionModal: DeleteFolderModalType = {
    isOpen: false,
    value: '',
    label: '',
  };

  isOpenDeleteDocumentModal = {
    isOpen: false,
    documentId: 0,
    name: '',
  };

  @observable isLoading: boolean = false;
  userInfo: UsersList | null = null;
  companyInfo: CompanyInfo | null = null;
  userId: string = localStorageWrapper.get(StorageKey._UserAuth)?.userId || '';
  companyId: string =
    localStorageWrapper.get(StorageKey._UserAuth)?.companyId || '';
  isVerifiedUser: boolean = !!localStorageWrapper.get(StorageKey._UserAuth)
    ?.isVerifiedUser;
  @observable folders: Folders[] = [];
  @observable docData: DocumentsData[] = [];
  @observable usersList: UsersList[] = [];
  settingSearch: SettingsSearch | null =
    localStorageWrapper.get(StorageKey._SettingsSearch) || null;
  widthScreen: number = 0;

  @observable infoSendEmail: boolean = false;
  @observable isLoadingAddUser: boolean = false;

  dataSearchNoLLM: DataRequestItem[] = [];

  isLoadingRequest: boolean = false;
  errorType: ErrorType = 'error';

  isLoadingRequestLLM: boolean = false;
  errorTypeLLM: ErrorType = 'error';

  isLoadingCollection: boolean = false;
  isLoadingBF: boolean = false;
  @observable messages: MessageData[] = [];
  isLoadingReloadCollection: LoadingStatus = LoadingStatus.none;
  alertData = {
    title: '',
    status: AlertStatus.success,
  };

  alertArray: AlertType[] = [];

  errorLocalesText: string =
    'На сервере произошла ошибка, попробуйте чуть позже';
  errorMessageDataRequest: string =
    'По вашему запросу нет подходящей информации в документах';
  errorNotFoundedText: string = 'Документ не найден';
  errorServer = {
    all: '',
    collection: '',
    errorMessageDataRequest: '',
    errorMessageDataRequestLLM: '',
    errorMessageCreateUser: '',
    errorMessageGetUsers: '',
    errorMessageDocuments: '',
    isErrorOnUpload: '',
    errorOnreloadDoc: '',
    errorMessageContact: '',
  };
  textOfAlers = {
    contactWithUs: {
      success: '',
    },
    documents: {
      reload: {
        error: '',
        success: '',
      },
      delete: {
        success: '',
      },
      upload: {
        success: '',
      },
    },
    folder: {
      delete: {
        success: '',
      },
    },
  };
  constructor() {
    makeAutoObservable(this);
    when(
      () => this.infoSendEmail,
      () => {
        let timeout = setTimeout(() => this.toggleModalUser(), 3000);
        return () => clearTimeout(timeout);
      },
    );
  }

  ///////////////////// ОБЫЧНЫЕ МЕТОДЫ ////////////////////
  public toggleModal: (this: Admin) => void = toggleModal.bind(this);

  public toggleModalUser: (this: Admin) => void = toggleModalUser.bind(this);

  public logout: (this: Admin) => void = logout.bind(this);

  public setWidthScreen: (this: Admin, width: number) => void =
    setWidthScreen.bind(this);

  public setInfoAddUSer: (this: Admin, bool: boolean) => void =
    setInfoAddUSer.bind(this);

  public setisLoadingAddUser: (this: Admin, bool: boolean) => void =
    setisLoadingAddUser.bind(this);

  public setIsOpenNavMenu: (this: Admin) => void = setIsOpenNavMenu.bind(this);

  public setInitSettingsSearch: (this: Admin) => void =
    setInitSettingsSearch.bind(this);

  public resetErrorSearch: (this: Admin) => void = resetErrorSearch.bind(this);

  public addAlert: (this: Admin, title: string, status: AlertStatus) => void =
    addAlert.bind(this);

  /////////////////////////////////////////////////////////

  /////////////// ОБЕРТКА ДЛЯ ВСЕХ ЗАПРОСОВ ///////////////

  protected performRequest: (
    this: Admin,
    config: AxiosRequestConfig,
    successCallback: (data: any) => void,
    failedCallback?: (error: AxiosError) => void,
  ) => Promise<void> = performRequest.bind(this);

  protected performRequestLLMSearch: <T>(
    this: Admin,
    config: AxiosRequestConfig,
    successCallback: (data: any) => void,
  ) => Promise<void> = performRequestLLMSearch.bind(this);

  /////////////////// ACTION МЕТОДЫ //////////////////

  @action protected addMessage = addMessage.bind(this);

  @action protected updateMessage = updateMessage.bind(this);

  /////////////////////////////////////////////////////////

  /////////////////// АСИНХРОННЫЕ МЕТОДЫ //////////////////
  public setSettingsSearch: (
    this: Admin,
    {
      returnCount,
      searchParams,
      searchParamsChunks,
      docsIndexes,
      docsWindow,
      maxOutputToken,
      companyCollection,
    }: SettingsSearchProps,
  ) => Promise<void> = setSettingsSearch.bind(this);

  public getCompanyInfo: (this: Admin) => Promise<void> =
    getCompanyInfo.bind(this);

  public getUsersList: (this: Admin) => Promise<void> = getUsersList.bind(this);

  public signIn: (this: Admin, requestData: SignInRequest) => Promise<void> =
    signIn.bind(this);

  public signUp: (this: Admin, signUpData: SignUpRequest) => Promise<void> =
    signUp.bind(this);

  public uploadFile: (
    this: Admin,
    uploadFileData: UploadFileRequest,
  ) => Promise<void> = uploadFile.bind(this);

  public reloadDocument: (
    this: Admin,
    documentId: string,
    name: string,
  ) => Promise<void> = reloadDocument.bind(this);

  public deleteDocument: (
    this: Admin,
    documentId: number,
    name: string,
  ) => Promise<void> = deleteDocument.bind(this);

  public downloadDocument: (
    this: Admin,
    documentId: number,
    name: string,
    format: string,
  ) => Promise<void> = downloadDocument.bind(this);

  public getFolders: (this: Admin) => Promise<void> = getFolders.bind(this);

  public getDocuments: (this: Admin) => Promise<void> = getDocuments.bind(this);

  public createCollection: (
    this: Admin,
    name: string,
    integrationName: string,
  ) => Promise<void> = createCollection.bind(this);

  public deleteCollection: (
    this: Admin,
    value: string,
    label: string,
  ) => Promise<void> = deleteCollection.bind(this);

  public onSendMessageLLM: (this: Admin, query: string) => Promise<void> =
    onSendMessageLLM.bind(this);

  public onSendMessageRequest: (this: Admin, query: string) => Promise<void> =
    onSendMessageRequest.bind(this);

  public createNewUserByAdmin: (
    this: Admin,
    createUserData: CreateUserRequest,
  ) => Promise<void> = createNewUserByAdmin.bind(this);

  public sendMessageFromContactWithUs: (
    this: Admin,
    sendMessageData: SendMessageContact,
  ) => Promise<void> = sendMessageFromContactWithUs.bind(this);

  public create_password: (
    this: Admin,
    createPasswordData: CreatePasswordRequest,
  ) => Promise<void> = create_password.bind(this);

  public clearLLMessage: (this: Admin) => Promise<void> =
    clearLLMessage.bind(this);

  /////////////////////////////////////////////////////////
}

export const storeAdmin = new Admin();
