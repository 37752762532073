/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable spaced-comment */
/* eslint-disable import/no-cycle */
///////////////////// Статичные МЕТОДЫ ////////////////////
import { localStorageWrapper } from '@/shared/lib/storageWrapper';
import { Admin, AlertStatus, StorageKey } from '../index';

/**
 * Переключает состояние модального окна.
 *
 * @type {(this: Admin) => void}
 * @this {Admin} Экземпляр класса
 */
export function toggleModal(this: Admin) {
  this.isOpenAdmin = !this.isOpenAdmin;
}

/**
 * Переключает состояние модального окна для пользователя.
 *
 * @type {(this: Admin) => void}
 * @this {Admin} Экземпляр класса
 */
export function toggleModalUser(this: Admin) {
  this.errorServer.errorMessageCreateUser = '';
  this.setInfoAddUSer(false);
  this.isOpenModalAddUser = !this.isOpenModalAddUser;
}

/**
 * Выполняет выход пользователя из системы.
 *
 * @type {(this: Admin) => void}
 * @this {Admin} Экземпляр класса
 */
export function logout(this: Admin) {
  this.userId = '';
  this.companyId = '';
  this.isVerifiedUser = false;
  this.dataSearchNoLLM = [];
  this.messages = [];

  localStorageWrapper.remove(StorageKey._UserAuth);
}

/**
 * Устанавливает ширину экрана.
 *
 * @type {(this: Admin, width: number) => void}
 * @param {number} width Ширина экрана, которая должна быть установлена.
 * @this {Admin} Экземпляр класса
 */
export function setWidthScreen(this: Admin, width: number) {
  this.widthScreen = width;
}

/**
 * Устанавливает информацию о добавлении пользователя.
 *
 * @type {(this: Admin, bool: boolean) => void}
 * @param {boolean} bool Логическое значение, определяющее, добавляется ли пользователь.
 * @this {Admin} Экземпляр класса
 */
export function setInfoAddUSer(this: Admin, bool: boolean) {
  this.infoSendEmail = bool;
}

/**
 * Устанавливает состояние загрузки при добавлении пользователя.
 *
 * @type {(this: Admin, bool: boolean) => void}
 * @param {boolean} bool Логическое значение, показывающее, идет ли загрузка при добавлении пользователя.
 * @this {Admin} Экземпляр класса
 */
export function setisLoadingAddUser(this: Admin, bool: boolean) {
  this.isLoadingAddUser = bool;
}

/**
 * Переключает состояние навигационного меню.
 *
 * @type {(this: Admin) => void}
 * @this {Admin} Экземпляр класса
 */
export function setIsOpenNavMenu(this: Admin) {
  this.isOpenNavMenu = !this.isOpenNavMenu;
}

/**
 * Инициализирует настройки поиска.
 *
 * @type {(this: Admin) => void}
 * @this {Admin} Экземпляр класса
 */
export function setInitSettingsSearch(this: Admin) {
  this.settingSearch = {
    requestID: String(this.userId),
    companyCollection: String(
      this.folders.length ? this.folders[0].integrationFolderName : '',
    ),
    integrationFolderName: String(
      this.folders.length ? this.folders[0].integrationFolderName : '',
    ),
    returnCount: 10,
    searchParams: ['all'],
    searchParamsChunks: ['all'],
    useLlm: true,
    useLlmCache: true,
    docsIndexes: 0,
    docsWindow: 3,
    docsWindowType: ['onDoc'],
    LLMParamsLookBack: 0,
    LLMParamsLookForward: 0,
    maxOutputToken: 2000,
    isJrCollection: false,
  };

  localStorageWrapper.set('settingSearch', this.settingSearch);
}

/**
 * Сбрасывает ошибки, связанные с поиском.
 *
 * @type {(this: Admin) => void}
 * @this {Admin} Экземпляр класса
 */
export function resetErrorSearch(this: Admin) {
  this.errorServer.errorMessageDataRequest = '';
}

export function addAlert(this: Admin, title: string, status: AlertStatus) {
  let maxId = this.alertArray.length > 0 ? this.alertArray[0].id : 0;
  for (let i = 1; i < this.alertArray.length; i++) {
    const currentItem = this.alertArray[i];
    const previousItem = this.alertArray[i - 1];

    if (currentItem.id === previousItem.id) {
      maxId++;
      currentItem.id = maxId;
    } else if (currentItem.id > maxId) {
      maxId = currentItem.id;
    }
  }
  maxId += 1;
  this.alertArray = [
    ...this.alertArray,
    {
      id: maxId,
      title,
      status,
      visible: true,
    },
  ];
}
