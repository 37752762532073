/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
import { Admin } from '../index';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { MessageData } from '../Types/responseType';

/**
 * Обертка запроса
 *
 * @type {(this: Admin, config: AxiosRequestConfig, successCallback: (data: T) => void, failedCallback?: (error: AxiosError) => void) => Promise<void>}
 * @param {AxiosRequestConfig} config конфиг
 * @param {T} successCallback Функция успешного запроса
 * @param {error: AxiosError} failedCallback Функция вызова ошибки
 * @this {Admin}  Экземпляр класса
 */
export async function performRequest<T>(
  this: Admin,
  config: AxiosRequestConfig,
  successCallback: (data: T) => void,
  failedCallback?: (error: AxiosError) => void,
) {
  this.isLoading = true;

  try {
    const { data } = await axios(config);
    successCallback(data);
  } catch (error) {
    if (!failedCallback) throw new Error((error as AxiosError).message);

    failedCallback(error as AxiosError);
  } finally {
    this.isLoading = false;
  }
}

/**
 * Обертка запроса
 *
 * @type {(this: Admin, config: AxiosRequestConfig, successCallback: (data: T) => void) => Promise<void>}
 * @param {AxiosRequestConfig} config конфиг
 * @param {T} successCallback Функция успешного запроса
 * @this {Admin}  Экземпляр класса
 */
export async function performRequestLLMSearch<T>(
  this: Admin,
  config: AxiosRequestConfig,
  successCallback: (data: T) => void,
) {
  this.isLoadingRequestLLM = true;
  this.errorServer.errorMessageDataRequestLLM = '';

  try {
    const { data } = await axios(config);
    successCallback(data);
  } catch (error: unknown) {
    this.errorServer.errorMessageDataRequestLLM = (error as Error).message;
    this.errorTypeLLM = 'error';
  } finally {
    this.isLoadingRequestLLM = false;
  }
}

/**
 * Добавление нового сообщения, исправление ошибки mobx (от переполнения)
 *
 * @type {(this: Admin, message: MessageData) => void}
 * @param {MessageData} message новое сообщение
 * @this {Admin}  Экземпляр класса
 */
export function addMessage(this: Admin, message: MessageData) {
  this.messages = [...this.messages, message];
}

/**
 * Обновление всех сообщений, исправление ошибки mobx (от переполнения)
 *
 * @type {(this: Admin, index: number, newLine: string) => void}
 * @param {number} index Индекс
 * @param {string} newLine Новая строка
 * @this {Admin}  Экземпляр класса
 */
export function updateMessage(this: Admin, index: number, newLine: string) {
  this.messages[index] = {
    ...this.messages[index],
    message: this.messages[index].message + newLine,
  };
}
